// Typeform ids
export const BABY_HAMPER_TYPEFORM_ID = 'Yx1BCr3t';

export const SOURCE_TYPEFORM_ID = 'v6VYTsSQ'; /* generic */
export const INTERACTIVE_REQUEST_TYPEFORM_ID = 'DjH1plMp';
export const BACK_TO_WORK_HAMPER_TYPEFORM_ID = 'jO1NgCyu';
export const CRECHE_HAMPER_TYPEFORM_ID = 'f81nyR8Z';

export const REQUEST_CALLBACK_TYPEFORM_ID = 'ksWXFbi6';
export const JOIN_WAITLIST_TYPEFORM_ID = 'pjIbTyb4';

export const CHALLENGE_US_TYPEFORM_ID = 'nfs2BKY6';

export const REQUEST_CALLBACK_HOTEL_ID = 'FPILyNlJ';

//not been used
export const SAFETY_POLICIES_CONTACT_US_TYPEFORM_ID = 'fKR3dOdx';
export const BOOK_CALL_TYPEFORM_ID_OLD = 'CAoIDKoC';
export const BOOK_CALL_TYPEFORM_ID = 'WhU5EPUi';
export const BOOK_DEMO_TYPEFORM_ID = 'ED27GWd5';
export const REQUEST_CALLBACK_TYPEFORM_ID_OLD = 'cMWSDibb';
