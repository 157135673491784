import { util as sdkUtil } from '../util/sdkLoader';
import { denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import * as log from '../util/log';
import { LISTING_STATE_DRAFT } from '../util/types';
import { storableError } from '../util/errors';
import { isUserAuthorized } from '../util/userHelpers';
import { getTransitionsNeedingProviderAttention } from '../transactions/transaction';

import { authInfo } from './auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { CREDITS_ORDER_TEMPLATE, sortCredits } from '../config/configCustomCredits';
import { transformSourceResults } from '../config/configCustomCluster';
import { USER_TYPE_EMPLOYER, USER_TYPE_MINDER, USER_TYPE_PARENT } from '../config/configCustomUser';
import { setAllCredits } from '../containers/SearchClaimPage/Credits.duck';
import isEqual from 'lodash/isEqual';
import { userLocation } from '../util/maps';
// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';
/* custom */
export const SET_CURRENT_USER_INFO = 'app/user/CURRENT_USER_INFO';
export const SET_CURRENT_USER_PROGRESS = 'app/user/CURRENT_USER_PROGRESS';
export const SET_CURRENT_USER_CORPORATE = 'app/user/CURRENT_USER_CORPORATE';
export const SET_IS_CURRENT_USER_INTERACTIVE = 'app/user/SET_IS_CURRENT_USER_INTERACTIVE';
export const SET_USER_LIST_INTERACTIVE = 'app/user/SET_USER_LIST_INTERACTIVE';

export const UPDATE_USER_ACCESS_REQUEST = 'app/user/UPDATE_USER_ACCESS_REQUEST';
export const UPDATE_USER_ACCESS_SUCCESS = 'app/user/UPDATE_USER_ACCESS_SUCCESS';
export const UPDATE_USER_ACCESS_ERROR = 'app/user/UPDATE_USER_ACCESS_ERROR';
export const UPDATE_HAS_FILLED_PROFILE = 'app/user/UPDATE_HAS_FILLED_PROFILE';
export const SET_USER_LOCATION = 'app/user/SET_USER_LOCATION';
export const SET_USER_LOCATION_ERROR = 'app/user/SET_USER_LOCATION_ERROR';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowTimestamp: 0,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  /* custom */
  currentUserRole: null,
  isCurrentUserMinder: false,
  isCurrentUserParent: false,
  isCurrentUserCorporate: false,
  currentUserCorporate: null,
  currentUserProgress: '0',
  isCurrentUserInteractive: false,
  userListInteractive: null,
  credits: [],
  updateUserAccessInProgress: false,
  updateUserAccessError: null,
  lastAccessTimes: null,
  hasFilledProfile: false,
  userLocation: null,
  userLocationError: null,
  signupInfo: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return {
        ...state,
        currentUser: mergeCurrentUser(state.currentUser, payload),
        currentUserShowTimestamp: payload ? new Date().getTime() : 0,
      };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
        // custom
        currentUserRole: null,
        isCurrentUserMinder: false,
        isCurrentUserParent: false,
        isCurrentUserCorporate: false,
        currentUserCorporate: null,
        currentUserProgress: '0',
        isCurrentUserInteractive: false,
        userListInteractive: null,
        credits: [],
        hasFilledProfile: false,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return { ...state, currentUserHasListings: payload.hasListings };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserNotificationCount: payload.transactions.length };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };
    /* custom */
    case SET_CURRENT_USER_INFO:
      return {
        ...state,
        currentUserRole: payload.role,
        hasFilledProfile: payload.hasFilledProfile,
        isCurrentUserMinder: isEqual(USER_TYPE_MINDER, payload.role),
        isCurrentUserParent: isEqual(USER_TYPE_PARENT, payload.role),
        signupInfo: payload.signupInfo,
      };
    case SET_CURRENT_USER_PROGRESS:
      return {
        ...state,
        currentUserProgress: payload.percentageCompleted || '0',
      };
    case SET_CURRENT_USER_CORPORATE:
      return {
        ...state,
        currentUserCorporate: payload.corporate,
        credits: payload.credits,
        isCurrentUserCorporate: true,
      };
    case SET_IS_CURRENT_USER_INTERACTIVE:
      return {
        ...state,
        isCurrentUserInteractive: payload,
      };
    case SET_USER_LIST_INTERACTIVE:
      return {
        ...state,
        userListInteractive: payload,
      };

    case UPDATE_USER_ACCESS_REQUEST:
      return {
        ...state,
        updateUserAccessInProgress: true,
        updateUserAccessError: null,
      };
    case UPDATE_USER_ACCESS_SUCCESS:
      return {
        ...state,
        lastAccessTimes: payload,
        updateUserAccessInProgress: false,
      };
    case UPDATE_USER_ACCESS_ERROR:
      return {
        ...state,
        updateUserAccessInProgress: false,
        updateUserAccessError: payload,
      };
    case UPDATE_HAS_FILLED_PROFILE:
      return {
        ...state,
        hasFilledProfile: true,
      };
    case SET_USER_LOCATION:
      return {
        ...state,
        userLocation: payload,
        userLocationError: null,
      };

    case SET_USER_LOCATION_ERROR:
      return {
        ...state,
        userLocationError: payload,
      };

    default:
      return state;
  }
}
// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

//custom
export const userAccessUpdateInProgress = state => {
  return state.user.updateUserAccessInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = hasListings => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});
/* custom */
export const setCurrentUserInfo = (role, hasFilledProfile, signupInfo) => ({
  type: SET_CURRENT_USER_INFO,
  payload: { role, hasFilledProfile, signupInfo },
});

export const setCurrentUserProgress = type => ({
  type: SET_CURRENT_USER_PROGRESS,
  payload: type,
});

export const setCurrentUserCorporate = (corporate, credits) => ({
  type: SET_CURRENT_USER_CORPORATE,
  payload: {
    corporate,
    credits,
  },
});

export const setIsCurrentUserInteractive = type => ({
  type: SET_IS_CURRENT_USER_INTERACTIVE,
  payload: type,
});
export const setUserListInteractive = type => ({
  type: SET_USER_LIST_INTERACTIVE,
  payload: type,
});

export const updateUserAccessRequest = () => ({
  type: UPDATE_USER_ACCESS_REQUEST,
});

export const updateUserAccessSuccess = lastAccessTimes => ({
  type: UPDATE_USER_ACCESS_SUCCESS,
  payload: lastAccessTimes,
});

export const updateUserAccessError = e => ({
  type: UPDATE_USER_ACCESS_ERROR,
  error: true,
  payload: e,
});
export const updateHasFilledProfile = () => ({
  type: UPDATE_HAS_FILLED_PROFILE,
});
export const setUserLocation = payload => ({
  type: SET_USER_LOCATION,
  payload: payload,
});
export const setUserLocationError = error => ({
  type: SET_USER_LOCATION_ERROR,
  payload: error,
  error: true,
});
// ================ Thunks ================ //

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has published
    // listings, we only need at most one result.
    states: 'published',
    page: 1,
    perPage: 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    perPage: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
  const transitionsNeedingAttention = getTransitionsNeedingProviderAttention();
  if (transitionsNeedingAttention.length === 0) {
    // Don't update state, if there's no need to draw user's attention after last transitions.
    return;
  }

  const apiQueryParams = {
    only: 'sale',
    last_transitions: transitionsNeedingAttention,
    page: 1,
    perPage: NOTIFICATION_PAGE_SIZE,
  };

  dispatch(fetchCurrentUserNotificationsRequest());
  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = response.data.data;
      dispatch(fetchCurrentUserNotificationsSuccess(transactions));
    })
    .catch(e => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchCurrentUser = options => (dispatch, getState, sdk) => {
  const state = getState();
  const { currentUserHasListings, currentUserShowTimestamp } = state.user || {};
  const { isAuthenticated } = state.auth;
  const { callParams = null, updateHasListings = true, updateNotifications = true, afterLogin } =
    options || {};

  // Double fetch might happen when e.g. profile page is making a full page load
  const aSecondAgo = new Date().getTime() - 1000;
  if (currentUserShowTimestamp > aSecondAgo) {
    return Promise.resolve({});
  }
  // Set in-progress, no errors
  dispatch(currentUserShowRequest());

  if (!isAuthenticated && !afterLogin) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = callParams || {
    include: ['effectivePermissionSet', 'profileImage', 'stripeAccount'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      // Retrieve user's profile and role from currentUser object
      const profile = currentUser?.attributes?.profile;
      const { hasFilledProfile: hasFilledProfileSaved, hasSearchCategory } =
        profile.protectedData || {};
      const hasCompleteProfile = () => {
        if (!!hasFilledProfileSaved) return true;
        const profileImageId = currentUser?.profileImage?.id;
        const { firstName, lastName, bio } = profile || {};
        return !!(firstName?.trim() && lastName?.trim() && bio?.trim() && profileImageId);
      };

      const hasFilledProfile = hasCompleteProfile();

      const role = profile?.publicData?.userType;
      //user may get access to airtable when user creates through corpo link.
      const isUserInteractive = profile?.protectedData?.airtable || false;
      // Check if user is a parent or employer to retrieve corporate information
      const corporate =
        role === USER_TYPE_PARENT || role === USER_TYPE_EMPLOYER
          ? profile?.protectedData?.corporate
          : null;

      const signupInfo = profile?.privateData?.signupInfo || null;
      const useLocationAPI =
        (signupInfo?.signupOrigin === 'oogoEvent' && role === USER_TYPE_MINDER) ||
        hasSearchCategory;
      dispatch(fetchUserLocation(useLocationAPI));

      // Set user's role in the application state
      if (role) {
        dispatch(setCurrentUserInfo(role, hasFilledProfile, signupInfo));
      }
      const hasSourceResults = !!profile?.publicData?.sourceResults;

      if (corporate || hasSourceResults) {
        try {
          const sourceResults = profile?.publicData?.sourceResults || [];
          const transformedSourceResults = transformSourceResults(sourceResults);
          // Get credits, creditsOrderSaved, and creditsOrder from profile.publicData
          const creditsSaved = profile?.publicData?.credits || [];
          const creditsOrderSaved = profile?.publicData?.creditsOrder || [];
          // Set creditsOrder to CREDITS_ORDER_TEMPLATE if creditsOrderSaved is empty
          const creditsOrder =
            creditsOrderSaved.length > 0 ? creditsOrderSaved : CREDITS_ORDER_TEMPLATE;
          // Sort credits based on creditsOrder
          const sortedCredits = sortCredits(creditsSaved, creditsOrder);

          dispatch(setAllCredits({ credits: creditsSaved, cluster: transformedSourceResults }));

          dispatch(setCurrentUserCorporate(corporate, sortedCredits));
        } catch (error) {
          // Handle the error here
          console.log('Error:', error);
        }
      }

      if (isUserInteractive) {
        dispatch(setIsCurrentUserInteractive(isUserInteractive));
      }
      return currentUser;
    })
    .then(currentUser => {
      // Retrieve user's profile and role from currentUser object
      const profile = currentUser?.attributes?.profile;
      const { hasFilledProfile: hasFilledProfileSaved, hasSearchCategory } =
        profile.protectedData || {};
      const hasCompleteProfile = () => {
        if (!!hasFilledProfileSaved) return true;
        const profileImageId = currentUser?.profileImage?.id;
        const { firstName, lastName, bio } = profile || {};
        return !!(firstName?.trim() && lastName?.trim() && bio?.trim() && profileImageId);
      };

      const hasFilledProfile = hasCompleteProfile();

      const role = profile?.publicData?.userType;
      //user may get access to airtable when user creates through corpo link.
      const isUserInteractive = profile?.protectedData?.airtable || false;
      // Check if user is a parent or employer to retrieve corporate information
      const corporate =
        role === USER_TYPE_PARENT || role === USER_TYPE_EMPLOYER
          ? profile?.protectedData?.corporate
          : null;

      const signupInfo = profile?.privateData?.signupInfo || null;
      const useLocationAPI =
        (signupInfo?.signupOrigin === 'oogoEvent' && role === USER_TYPE_MINDER) ||
        hasSearchCategory;
      dispatch(fetchUserLocation(useLocationAPI));

      // Set user's role in the application state
      if (role) {
        dispatch(setCurrentUserInfo(role, hasFilledProfile, signupInfo));
      }
      const hasSourceResults = !!profile?.publicData?.sourceResults;

      if (corporate || hasSourceResults) {
        try {
          const sourceResults = profile?.publicData?.sourceResults || [];
          const transformedSourceResults = transformSourceResults(sourceResults);
          // Get credits, creditsOrderSaved, and creditsOrder from profile.publicData
          const creditsSaved = profile?.publicData?.credits || [];
          const creditsOrderSaved = profile?.publicData?.creditsOrder || [];
          // Set creditsOrder to CREDITS_ORDER_TEMPLATE if creditsOrderSaved is empty
          const creditsOrder =
            creditsOrderSaved.length > 0 ? creditsOrderSaved : CREDITS_ORDER_TEMPLATE;
          // Sort credits based on creditsOrder
          const sortedCredits = sortCredits(creditsSaved, creditsOrder);

          dispatch(setAllCredits({ credits: creditsSaved, cluster: transformedSourceResults }));

          dispatch(setCurrentUserCorporate(corporate, sortedCredits));
        } catch (error) {
          // Handle the error here
          console.log('Error:', error);
        }
      }

      if (isUserInteractive) {
        dispatch(setIsCurrentUserInteractive(isUserInteractive));
      }
      return currentUser;
    })
    .then(currentUser => {
      // If currentUser is not active (e.g. in 'pending-approval' state),
      // then they don't have listings or transactions that we care about.
      if (isUserAuthorized(currentUser)) {
        if (currentUserHasListings === false && updateHasListings !== false) {
          dispatch(fetchCurrentUserHasListings());
        }

        if (updateNotifications !== false) {
          dispatch(fetchCurrentUserNotifications());
        }

        if (!currentUser.attributes.emailVerified) {
          dispatch(fetchCurrentUserHasOrders());
        }
      }

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};

export const updateUserAccess = params => (dispatch, getState, sdk) => {
  const { lastAccessTimes } = getState().user;
  const { isAuthenticated, authScopes } = getState().auth;
  const isUserLimited = authScopes && authScopes[0] === 'user:limited';
  if (!isAuthenticated || isUserLimited) {
    return;
  }

  const timeNow = new Date();

  // Retrieve the correct timestamp from the user state based on params
  const lastAccessTime = lastAccessTimes && lastAccessTimes[params.userAccess];
  const timeDifference = Math.abs(timeNow - new Date(lastAccessTime)) / 60000; // difference in minutes

  if (timeDifference < 1) {
    return;
  }
  if (userAccessUpdateInProgress(getState())) {
    return;
  }

  const timeNowJSON = timeNow.toJSON();
  const updatedLastAccessTimes = {
    ...lastAccessTimes,
    [params.userAccess]: timeNowJSON,
    last_user_access_at: timeNowJSON, // Always update this regardless of access type
  };

  dispatch(updateUserAccessRequest());
  return sdk.currentUser
    .updateProfile({ privateData: updatedLastAccessTimes })
    .then(() => dispatch(updateUserAccessSuccess(updatedLastAccessTimes)))
    .catch(e => dispatch(updateUserAccessError(storableError(e))));
};

/* custom */
export const setHasFilledProfile = () => (dispatch, getState, sdk) => {
  dispatch(updateHasFilledProfile());
};

export const fetchUserLocation = useLocationAPI => (dispatch, getState, sdk) => {
  if (!useLocationAPI) {
    return;
  }

  const CACHE_KEY = 'userLocation';
  const CACHE_EXPIRATION = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

  const getCachedLocation = () => {
    if (typeof localStorage === 'undefined') {
      return null;
    }
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_EXPIRATION) {
        return data;
      }
    }
    return null;
  };

  const setCachedLocation = data => {
    if (typeof localStorage === 'undefined') {
      return;
    }
    const cachedData = {
      data,
      timestamp: Date.now(),
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cachedData));
  };

  const cachedLocation = getCachedLocation();
  if (cachedLocation) {
    dispatch(setUserLocation(cachedLocation));
    return;
  }

  const fetchLocationFromGeoplugin = async () => {
    const response = await fetch('http://www.geoplugin.net/json.gp');
    if (!response.ok) {
      throw new Error('Failed to fetch location from geoplugin.net');
    }
    const data = await response.json();
    const { geoplugin_city, geoplugin_countryName, geoplugin_latitude, geoplugin_longitude } = data;
    if (geoplugin_city && geoplugin_countryName && geoplugin_latitude && geoplugin_longitude) {
      return {
        city: geoplugin_city,
        country: geoplugin_countryName,
        lat: parseFloat(geoplugin_latitude),
        lon: parseFloat(geoplugin_longitude),
      };
    } else {
      throw new Error('Incomplete location data received from geoplugin.net');
    }
  };

  const fetchLocationFromIpapi = async () => {
    const response = await fetch('https://ipapi.co/json/');
    if (!response.ok) {
      throw new Error('Failed to fetch location from ipapi.co');
    }
    const data = await response.json();

    // Ensure required fields are present in data
    const { city, country_name, latitude, longitude } = data;
    if (city && country_name && latitude && longitude) {
      return {
        city,
        country: country_name,
        lat: latitude,
        lon: longitude,
      };
    } else {
      throw new Error('Incomplete location data received from ipapi.co');
    }
  };

  const fetchLocationFromIpguide = async () => {
    const response = await fetch('https://ip.guide/');
    if (!response.ok) {
      throw new Error('Failed to fetch location from ip.guide');
    }
    const data = await response.json();

    // Ensure required fields are present in data
    const { city, country, latitude, longitude } = data.location;
    if (city && country && latitude && longitude) {
      return {
        city,
        country,
        lat: latitude,
        lon: longitude,
      };
    } else {
      throw new Error('Incomplete location data received from ip.guide');
    }
  };

  const fetchLocation = async () => {
    try {
      const userLocation = await fetchLocationFromGeoplugin();
      setCachedLocation(userLocation);
      dispatch(setUserLocation(userLocation));
    } catch (err) {
      console.error('Error fetching location from ipapi.co:', err.message);
      try {
        const fallbackLocation = await fetchLocationFromIpguide();
        setCachedLocation(fallbackLocation);
        dispatch(setUserLocation(fallbackLocation));
      } catch (fallbackErr) {
        console.error('Error fetching location from ip.guide:', fallbackErr.message);
        dispatch(setUserLocationError(fallbackErr.message));
      }
    }
  };

  fetchLocation();
};
