import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/SourceResults/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/SourceResults/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/SourceResults/FETCH_LISTINGS_ERROR';

export const UPDATE_SOURCE_RESULTS_REQUEST = 'app/SourceResults/UPDATE_SOURCE_RESULTS_REQUEST';
export const UPDATE_SOURCE_RESULTS_SUCCESS = 'app/SourceResults/UPDATE_SOURCE_RESULTS_SUCCESS';
export const UPDATE_SOURCE_RESULTS_ERROR = 'app/SourceResults/UPDATE_SOURCE_RESULTS_ERROR';

export const FETCH_BOOKING_STATS_REQUEST = 'app/SourceResults/FETCH_BOOKING_STATS_REQUEST';
export const FETCH_BOOKING_STATS_SUCCESS = 'app/SourceResults/FETCH_BOOKING_STATS_SUCCESS';
export const FETCH_BOOKING_STATS_ERROR = 'app/SourceResults/FETCH_BOOKING_STATS_ERROR';

export const FETCH_NEXT_BOOKING_REQUEST = 'app/SourceResults/FETCH_NEXT_BOOKING_REQUEST';
export const FETCH_NEXT_BOOKING_SUCCESS = 'app/SourceResults/FETCH_NEXT_BOOKING_SUCCESS';
export const FETCH_NEXT_BOOKING_ERROR = 'app/SourceResults/FETCH_NEXT_BOOKING_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  querySourceResultsError: null,
  currentPageResultIds: [],
  sourceResultsRedux: null,
  updateSourceResultsInProgress: false,
  updateSourceResultsError: null,
  queryBookingStatsInProgress: false,
  queryBookingStatsError: null,
  totalComplete: 0,
  totalConfirmPayment: 0,
  totalAccept: 0,
  closestBooking: null,
  queryNextBookingInProgress: false,
  queryNextBookingError: null,
};

const resultIds = data => data.data.map(l => l.id);

const sourceResultsReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        querySourceResultsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryInProgress: false,
        querySourceResultsError: payload,
      };
    case UPDATE_SOURCE_RESULTS_REQUEST:
      return {
        ...state,
        updateSourceResultsInProgress: true,
        updateSourceResultsError: null,
      };
    case UPDATE_SOURCE_RESULTS_SUCCESS:
      return {
        ...state,
        updateSourceResultsInProgress: false,
        sourceResultsRedux: payload,
      };
    case UPDATE_SOURCE_RESULTS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        updateSourceResultsInProgress: false,
        updateSourceResultsError: payload,
      };
    case FETCH_BOOKING_STATS_REQUEST:
      return {
        ...state,
        queryBookingStatsInProgress: true,
        queryBookingStatsError: null,
      };
    case FETCH_BOOKING_STATS_SUCCESS:
      return {
        ...state,
        totalComplete: payload.data.totalComplete,
        totalConfirmPayment: payload.data.totalConfirmPayment,
        totalAccept: payload.data.totalAccept,
        closestBooking: payload.data.closestBooking,
        queryBookingStatsInProgress: false,
      };
    case FETCH_BOOKING_STATS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryBookingStatsInProgress: false,
        queryBookingStatsError: payload,
      };

    case FETCH_NEXT_BOOKING_REQUEST:
      return {
        ...state,
        queryNextBookingInProgress: true,
        queryNextBookingError: null,
      };
    case FETCH_NEXT_BOOKING_SUCCESS:
      return {
        ...state,
        queryNextBookingInProgress: false,
      };
    case FETCH_NEXT_BOOKING_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryNextBookingInProgress: false,
        queryNextBookingError: payload,
      };

    default:
      return state;
  }
};

export default sourceResultsReducer;

// ================ Action creators ================ //

export const querySourceResultsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const querySourceResultsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const querySourceResultsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const updateSourceResultsRequest = () => ({
  type: UPDATE_SOURCE_RESULTS_REQUEST,
});

export const updateSourceResultsSuccess = params => ({
  type: UPDATE_SOURCE_RESULTS_SUCCESS,
  payload: params,
});

export const updateSourceResultsError = e => ({
  type: UPDATE_SOURCE_RESULTS_ERROR,
  error: true,
  payload: e,
});
export const queryBookingStatsRequest = () => ({
  type: FETCH_BOOKING_STATS_REQUEST,
});

export const queryBookingStatsSuccess = response => ({
  type: FETCH_BOOKING_STATS_SUCCESS,
  payload: { data: response },
});

export const queryBookingStatsError = e => ({
  type: FETCH_BOOKING_STATS_ERROR,
  error: true,
  payload: e,
});
export const queryNextBookingRequest = () => ({
  type: FETCH_NEXT_BOOKING_REQUEST,
});

export const queryNextBookingSuccess = response => ({
  type: FETCH_NEXT_BOOKING_SUCCESS,
  payload: { data: response },
});

export const queryNextBookingError = e => ({
  type: FETCH_NEXT_BOOKING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

// Throwing error for new (loadData may need that info)
export const querySourceResultsListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(querySourceResultsRequest(queryParams));
  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(querySourceResultsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(querySourceResultsError(storableError(e)));
      throw e;
    });
};

export const updateSourceResults = params => (dispatch, getState, sdk) => {
  dispatch(updateSourceResultsRequest());
  return sdk.currentUser
    .updateProfile({
      publicData: { sourceResults: params },
    })
    .then(response => {
      dispatch(updateSourceResultsSuccess(params));
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      dispatch(fetchCurrentUser());
      return currentUser;
    })
    .catch(e => {
      dispatch(updateSourceResultsError(storableError(e)));
      // pass the same error so that the UPDATE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

export const queryBookingStats = config => (dispatch, getState, sdk) => {
  dispatch(queryBookingStatsRequest());

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  const { isCurrentUserMinder } = getState().user;
  const transactionType = isCurrentUserMinder ? 'sale' : 'order';

  return sdk.transactions
    .query({
      only: transactionType,
      lastTransitions: [
        'transition/confirm-payment',
        'transition/accept',
        'transition/complete',
        'transition/review-1-by-provider',
        'transition/review-2-by-provider',
        'transition/review-1-by-customer',
        'transition/review-2-by-customer',
        'transition/expire-review-period',
        'transition/expire-provider-review-period',
        'transition/expire-customer-review-period',
        'transition/operator-complete',
        'transition/operator-accept',
      ],
      processNames: ['default-booking'],
      include: ['booking', 'listing', 'listing.images'],
      'fields.booking': ['start', 'end'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      // Initialize counters
      let totalComplete = 0;
      let totalConfirmPayment = 0;
      let totalAccept = 0;
      let closestBooking = null;

      // Count each transition type
      entities.forEach(entity => {
        const startDate = new Date(entity.booking.attributes.start);
        switch (entity.attributes.lastTransition) {
          case 'transition/operator-complete':
            totalComplete += 1;
            break;
          case 'transition/complete':
            totalComplete += 1;
            break;
          case 'transition/review-1-by-provider':
            totalComplete += 1;
            break;
          case 'transition/review-2-by-provider':
            totalComplete += 1;
            break;
          case 'transition/review-1-by-customer':
            totalComplete += 1;
            break;
          case 'transition/review-2-by-customer':
            totalComplete += 1;
            break;
          case 'transition/expire-review-period':
            totalComplete += 1;
            break;
          case 'transition/expire-provider-review-period':
            totalComplete += 1;
            break;
          case 'transition/expire-customer-review-period':
            totalComplete += 1;
            break;
          case 'transition/confirm-payment':
            totalConfirmPayment += 1;
            break;
          case 'transition/accept':
            totalAccept += 1;

            if (!closestBooking || startDate < new Date(closestBooking.booking.attributes.start)) {
              closestBooking = entity;
            }
            break;
          case 'transition/operator-accept':
            totalAccept += 1;

            if (!closestBooking || startDate < new Date(closestBooking.booking.attributes.start)) {
              closestBooking = entity;
            }
            break;
        }
      });

      dispatch(
        queryBookingStatsSuccess({
          totalComplete,
          totalConfirmPayment,
          totalAccept,
          closestBooking,
        })
      );
    })
    .catch(e => {
      dispatch(queryBookingStatsError(storableError(e)));
      // pass the same error so that the UPDATE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};
export const queryNextBooking = (params, config) => (dispatch, getState, sdk) => {
  dispatch(queryNextBookingRequest());

  return;
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  const listingIds = { ids: params };

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return querySourceResultsListings({
    ...queryParams,
    ...listingIds,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });
};
