//Onboarding Types
export const ONBOARDING_TYPE_SERVICE = 'serviceListing';
export const ONBOARDING_TYPE_JOB = 'jobListing';

// Category types
export const CATEGORY_TYPE_CHILDCARE = 'childcare';
export const CATEGORY_TYPE_CHILDCARE_FULL_TIME = 'childcare_full_time';
export const CATEGORY_TYPE_TUTOR = 'tutor';
export const CATEGORY_TYPE_PREGNANCY_AND_BIRTH = 'pregnancy_and_birth';
export const CATEGORY_TYPE_MATERNITY_NURSE = 'maternity_nurse';
export const CATEGORY_TYPE_ACTIVITY_BASED = 'activity_based';
export const CATEGORY_TYPE_CRECHE = 'creche';
export const CATEGORY_TYPE_CRECHE_JOB = 'creche_job';

// Minimum number of photos.
export const MIN_PHOTOS_MINDER_LISTING = 4;

//minimun characters
export const getMinCharacters = tab => {
  // Define the minimum characters for each tab
  const minCharacters = {
    dearFamily: 100,
    favMemory: 100,
    personality: 100,
    myFamily: 100,
    eduLanguages: 100,
    interests: 100,
    motivation: 100,
    experience: 100,
    expectation: 100,
    skillsTalents: 100,
    approachPhilosophy: 100,
    facilitiesAmenities: 100,
    staffQualifications: 100,
    safetySecurityMeasures: 100,
    nutritionMealPlans: 100,
    parentCommunicationUpdates: 100,
    inclusionDiversity: 100,
    aboutFamily: 100,
    jobDescription: 100,
    familyRoutine: 100,
    homeEnvironment: 100,
    parentingStyle: 100,
    familyExpectations: 100,
    healthNotes: 100,
    compensationBenefits: 100,
    qualificationsExperience: 100,
    additionalInfo: 100,
    familyBudget: 20,
    servicesTechnique: 100,
  };
  // Return the minimum characters for the specified tab, or null if not found
  return minCharacters[tab] || null;
};

/**
 * This function checks if a custom field should return null based on certain conditions.
 * It evaluates an array of condition objects, where each condition specifies:
 * - `keys`: An array of field keys that should trigger the condition.
 * - `check`: A function that takes `publicData` and checks if the condition is met.
 **/
export const shouldReturnNullConditionForField = (customFieldProps, publicData = {}) => {
  const key = customFieldProps?.key;

  // Validate key
  if (!key) {
    return false;
  }

  // List of null return conditions
  const nullReturnConditions = [
    {
      // Check function combined into one dynamic check function
      check: (key, { jobLocation = '' }) => {
        const keys = ['homeEnvironment', 'familyRoutine'];
        return (
          keys.includes(key) &&
          jobLocation.includes('minder_home') &&
          !jobLocation.includes('parents_home')
        );
      },
    },
    {
      check: key => {
        const keys = [
          'nationality',
          'opening_hours',
          'weekSchedule',
          'jobLocation',
          'jobLocation_tutor',
          'jobLocation_sleep_consultant',
          'jobType',
          'livingChoice',
          'minderListingIds',
        ];
        return keys.includes(key);
      },
    },
    {
      check: (key, { jobLocation = '' }) => {
        const keys = ['familyRoutine'];
        return (
          keys.includes(key) &&
          jobLocation.includes('minder_home') &&
          !jobLocation.includes('parents_home')
        );
      },
    },
    // Add more condition objects as needed
  ];

  // Check if any condition is met
  return nullReturnConditions.some(condition => {
    try {
      return condition.check(key, publicData);
    } catch (error) {
      // Log the error if needed
      console.error('Error evaluating condition:', error);
      return false;
    }
  });
};

export const TYPE_TEXT_LINE_FIELDS = ['nationality', 'opening_hours'];

export const DETAILS_FIELDS = [];
export const EXTENDED_DETAILS_FIELDS_CHILDCARE = [
  'startDateJob',
  'endDateJob',
  'durationMin',
  'durationMax',
  'nationality',
  'jobLanguages',
  'livingChoice',
  'jobLocation',
];
export const EXTENDED_DETAILS_FIELDS_CHILDCARE_JOB = [
  'startDateJob',
  'endDateJob',
  'durationMin',
  'durationMax',
  'jobLanguages',
  'livingChoice',
  'jobLocation',
];
export const EXTENDED_DETAILS_FIELDS_TUTOR = [
  'startDateJob',
  'endDateJob',
  'nationality',
  'jobLanguages',
  'jobLocation_tutor',
  'classTypeOptions',
  'subjectOptions',
  'ageGroup_tutor',
];
export const EXTENDED_DETAILS_FIELDS_TUTOR_JOB = [
  'startDateJob',
  'endDateJob',
  'jobLanguages',
  'jobLocation_tutor',
  'classTypeOptions',
  'subjectOptions',
  'ageGroup_tutor',
];
export const EXTENDED_DETAILS_FIELDS_PREGNANCY_AND_BIRTH = [
  'jobType_pregnancy_and_birth',
  'nationality',
  'jobLocation_pregnancy_and_birth',
];
export const EXTENDED_DETAILS_FIELDS_PREGNANCY_AND_BIRTH_JOB = [
  'jobType_pregnancy_and_birth',
  'jobLocation_pregnancy_and_birth',
];
export const EXTENDED_DETAILS_FIELDS_ACTIVITY_BASED = [
  'activityType',
  'startDateJob',
  'endDateJob',
  'nationality',
  'jobLanguages',
  'ageGroup_activity_based',
  'activityLocation',
  'activityOptions',
];
export const EXTENDED_DETAILS_FIELDS_ACTIVITY_BASED_JOB = [
  'activityType',
  'startDateJob',
  'endDateJob',
  'jobLanguages',
  'ageGroup_activity_based',
  'activityLocation',
  'activityOptions',
];
export const EXTENDED_DETAILS_FIELDS_CRECHE = [
  'operatingHours',
  'jobLanguages',
  'ageGroup_creche',
  'facilities',
];
// Function to get the relevant fields array based on the current tab
export const getFieldsForTab = (tab, category, onboardingType) => {
  const extendedDetailsFieldsMap = {
    childcare:
      onboardingType === ONBOARDING_TYPE_SERVICE
        ? [...EXTENDED_DETAILS_FIELDS_CHILDCARE]
        : [...EXTENDED_DETAILS_FIELDS_CHILDCARE_JOB],
    childcare_full_time:
      onboardingType === ONBOARDING_TYPE_SERVICE
        ? [...EXTENDED_DETAILS_FIELDS_CHILDCARE]
        : [...EXTENDED_DETAILS_FIELDS_CHILDCARE_JOB],
    tutor:
      onboardingType === ONBOARDING_TYPE_SERVICE
        ? [...EXTENDED_DETAILS_FIELDS_TUTOR]
        : [...EXTENDED_DETAILS_FIELDS_TUTOR_JOB],
    pregnancy_and_birth:
      onboardingType === ONBOARDING_TYPE_SERVICE
        ? [...EXTENDED_DETAILS_FIELDS_PREGNANCY_AND_BIRTH]
        : [...EXTENDED_DETAILS_FIELDS_PREGNANCY_AND_BIRTH_JOB],
    activity_based:
      onboardingType === ONBOARDING_TYPE_SERVICE
        ? [...EXTENDED_DETAILS_FIELDS_ACTIVITY_BASED]
        : [...EXTENDED_DETAILS_FIELDS_ACTIVITY_BASED_JOB],
    creche: [...EXTENDED_DETAILS_FIELDS_CRECHE],
  };
  switch (tab) {
    case 'details':
      return DETAILS_FIELDS;
    case 'extendedDetails':
      return extendedDetailsFieldsMap[category] || [];
    // Add cases for other tabs here
    default:
      return [];
  }
};
