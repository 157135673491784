import React, { useState, useEffect } from 'react';
import css from './CustomDynamicLogo.module.css';
const images = require.context('../CorporateLogo/LogoImages', false, /\.(png|jpe?g|svg)$/);

const CustomDynamicLogo = ({ title }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (!title) {
      setImageSrc(null);
      return;
    }

    const firstWord = title.split(' ')[0].toLowerCase();

    const checkImage = format => {
      try {
        return images(`./${firstWord}.${format}`);
      } catch (error) {
        return null;
      }
    };

    const formats = ['png', 'svg', 'jpg'];

    for (let format of formats) {
      const url = checkImage(format);
      if (url) {
        setImageSrc(url);
        return; // Exit the loop once the image is found
      }
    }

    // If no image is found, set to null
    setImageSrc(null);
  }, [title]);

  if (!title) {
    return null;
  }

  return (
    <div className={css.imageContainer}>
      {imageSrc ? (
        <img
          src={imageSrc}
          alt={`${title.split(' ')[0].toLowerCase()} Icon`}
          className={css.image}
        />
      ) : (
        <p>Image not available</p>
      )}
    </div>
  );
};

export default CustomDynamicLogo;
