import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';
import { getCredits } from '../../config/configCustomCredits';

// ================ Action types ================ //

export const UPDATE_CREDITS_REQUEST = 'app/SearchClaimPage/UPDATE_CREDITS_REQUEST';
export const UPDATE_CREDITS_SUCCESS = 'app/SearchClaimPage/UPDATE_CREDITS_SUCCESS';
export const UPDATE_CREDITS_ERROR = 'app/SearchClaimPage/UPDATE_CREDITS_ERROR';
export const SET_CREDITS_REQUEST = 'app/SearchClaimPage/SET_CREDITS_REQUEST';
export const SET_CREDITS_SUCCESS = 'app/SearchClaimPage/SET_CREDITS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  updateCreditsInProgress: false,
  creditsChanged: false,
  updateCreditsError: null,
  availableCredits: [],
  usedCredits: [],
  inProgressCredits: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CREDITS_REQUEST:
      return {
        ...state,
        updateCreditsInProgress: true,
        creditsChanged: false,
        updateCreditsError: null,
      };
    case UPDATE_CREDITS_SUCCESS:
      return { ...state, updateCreditsInProgress: false, creditsChanged: true };
    case UPDATE_CREDITS_ERROR:
      return { ...state, updateCreditsInProgress: false, updateCreditsError: payload };
    case SET_CREDITS_REQUEST:
      return {
        ...state,
        updateCreditsInProgress: false,
        creditsChanged: true,
        availableCredits: payload.availableCredits,
        usedCredits: payload.usedCredits,
        inProgressCredits: payload.inProgressCredits,
      };

    case SET_CREDITS_SUCCESS:
      return { ...state, updateCreditsInProgress: true, creditsChanged: false };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const updateCreditsRequest = () => ({ type: UPDATE_CREDITS_REQUEST });
export const updateCreditsSuccess = () => ({ type: UPDATE_CREDITS_SUCCESS });
export const updateCreditsError = error => ({
  type: UPDATE_CREDITS_ERROR,
  payload: error,
  error: true,
});

export const setCreditsRequest = (availableCredits, usedCredits, inProgressCredits) => ({
  type: SET_CREDITS_REQUEST,
  payload: { availableCredits, usedCredits, inProgressCredits },
});
export const setCreditsSucess = () => ({ type: SET_CREDITS_SUCCESS });

// ================ Thunks ================ //

const updateVoucherIsNew = (template, key, voucherId, counts) => {
  const updatedTemplate = template.map(credit => {
    if (credit.key === key) {
      const updatedVouchers = credit.params.vouchers.map(voucher => {
        if (voucher.id === voucherId) {
          return {
            ...voucher,
            isNew: false,
          };
        }
        return voucher;
      });
      return {
        ...credit,
        params: {
          ...credit.params,
          available: counts.available - 1,
          used: counts.used + 1,
          vouchers: updatedVouchers,
        },
      };
    }
    return credit;
  });

  return updatedTemplate;
};

/**
 * Make a credits update request to the API and return the current user.
 */
const requestUpdateCredits = params => (dispatch, getState, sdk) => {
  const voucherId = params.voucher.id;
  const credits = params.credits;
  const key = params.key;
  const counts = params.counts;
  // Usage
  const updatedCredits = updateVoucherIsNew(credits, key, voucherId, counts);

  return sdk.currentUser
    .updateProfile(
      { publicData: { credits: updatedCredits } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      dispatch(fetchCurrentUser());
      return currentUser;
    })
    .catch(e => {
      dispatch(updateCreditsError(storableError(e)));
      // pass the same error so that the UPDATE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Save credits and update the current user.
 */
const updateCredits = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestUpdateCredits(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(updateCreditsSuccess());
      })
      // error action dispatched in requestUpdateCredits
      .catch(e => null)
  );
};

export const setAllCredits = params => (dispatch, getState, sdk) => {
  const { credits, cluster } = params;
  //different pointer in memory
  const mutableCredits = JSON.parse(JSON.stringify(credits));
  const { availableCredits, usedCredits, inProgressCredits } = getCredits(mutableCredits, cluster);
  dispatch(setCreditsSucess());
  dispatch(setCreditsRequest(availableCredits, usedCredits, inProgressCredits));
};

/**
 * Update credits
 */
export const saveCredits = params => (dispatch, getState, sdk) => {
  dispatch(updateCreditsRequest());

  const { key, type, credits, ...rest } = params;

  if (type === 'voucher') {
    const voucher = rest?.voucher;
    const isValid = voucher.id === voucher.index + 1;
    const counts = rest?.counts;

    if (isValid) return dispatch(updateCredits({ credits, key, voucher, counts }));
  }
};
