import React, { useState, useEffect } from 'react';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const PackageSelector = ({ packages, hasPackages, onSelectedPackage }) => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState('');

  const handleSelectPackage = pkg => {
    const toMoney = new Money(pkg.price.amount, pkg.price.currency);
    const newPackageWithMoneyPrice = {
      ...pkg,
      price: toMoney,
    };
    onSelectedPackage(newPackageWithMoneyPrice);
  };

  useEffect(() => {
    if (hasPackages && packages.length === 1) {
      handleSelectPackage(packages[0]);
    }
  }, [packages, hasPackages]);

  useEffect(() => {
    if (selectedPackageIndex) {
      handleSelectPackage(selectedPackage);
    }
  }, [selectedPackageIndex, packages]);

  /* useEffect(() => {
    if (selectedPackageIndex) {
      const toMoney = new Money(selectedPackage.price.amount, selectedPackage.price.currency);
      const newPackageWithMoneyPrice = {
        ...selectedPackage,
        price: toMoney,
      };
      onSelectedPackage(newPackageWithMoneyPrice);
    }
  }, [selectedPackageIndex, packages]); */

  if (hasPackages && packages.length === 1) {
    const pkg = packages[0];
    return (
      <div>
        <strong>Package:</strong>
        <p>{pkg.packageName}</p>
      </div>
    );
  }

  return (
    <>
      {hasPackages ? (
        <>
          <label htmlFor="package-select">Package</label>
          <select
            id="package-select"
            name="packageName"
            value={selectedPackageIndex}
            onChange={e => {
              const index = e.target.value;
              const pkg = packages[index];
              setSelectedPackageIndex(index);
              setSelectedPackage(pkg);
            }}
          >
            <option disabled value="">
              Select a package
            </option>
            {packages.map((pkg, index) => (
              <option key={index} value={index}>
                {pkg.packageName}
              </option>
            ))}
          </select>
        </>
      ) : null}
    </>
  );
};
export default PackageSelector;
