import React from 'react';
import { connect } from 'react-redux';

// Import the CSS module for the CorporateLogo component
import css from './CorporateLogo.module.css';
import { resolveCompanyConfig } from '../../config/configCustomUser';

// Define the CorporateLogo component
const CorporateLogo = props => {
  // Destructure the props object to get the currentUserCorporate and format values
  const { currentUserCorporate, layout } = props;

  if (!currentUserCorporate) return null;

  const currentClass = layout === 'desktop' ? css.corporationDesktop : css.corporationMobile;
  const corporateConfig = resolveCompanyConfig(currentUserCorporate);

  if (!corporateConfig) {
    return null;
  }

  const { logo, logoStyle } = corporateConfig;

  return <img src={logo} alt={currentUserCorporate} className={currentClass} style={logoStyle} />;
};
const mapStateToProps = state => ({
  currentUserCorporate: state.user.currentUserCorporate,
});
// Export the CorporateLogo component as the default export
export default connect(mapStateToProps)(CorporateLogo);
