import { updateUserAccess } from '../ducks/user.duck';

export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('Analytics page view:', url);
  }
}

// Google Analytics 4 (GA4) using gtag.js script, which is included in util/includeScripts.js
export class GoogleAnalyticsHandler {
  trackPageView(canonicalPath, previousPath) {
    // GA4 property. Manually send page_view events
    // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
    // Note 1: You should turn "Enhanced measurement" off.
    //         It attaches own listeners to elements and that breaks in-app navigation.
    // Note 2: If previousPath is null (just after page load), gtag script sends page_view event automatically.
    //         Only in-app navigation needs to be sent manually from SPA.
    // Note 3: Timeout is needed because gtag script picks up <title>,
    //         and location change event happens before initial rendering.
    if (previousPath && window.gtag) {
      window.setTimeout(() => {
        window.gtag('event', 'page_view', {
          page_path: canonicalPath,
        });
      }, 300);
    }
  }
}
export class ReduxAnalyticsHandler {
  constructor(getStore) {
    // getStore is a function that returns the current store instance
    this.getStore = getStore;
    this.lastDispatchTime = 0;
  }

  trackPageView(canonicalPath, previousPath) {
    // Ensure the getStore function is available
    if (this.getStore) {
      const store = this.getStore();
      const { isAuthenticated, authScopes } = store.getState().auth;
      const currentTime = Date.now();
      const isDispatchIntervalElapsed = currentTime - this.lastDispatchTime >= 60000;
      const isUserLimited = authScopes && authScopes[0] === 'user:limited';
      if (!isUserLimited && canonicalPath && isAuthenticated && isDispatchIntervalElapsed) {
        this.lastDispatchTime = currentTime;
        store.dispatch(updateUserAccess({ userAccess: 'last_user_access_at' }));
      }
    }
  }
}
