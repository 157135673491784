import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import { connect } from 'react-redux';
import css from './UserNav.module.css';

const UserNav = props => {
  const {
    className,
    rootClassName,
    currentPage,
    /* custom */ isCurrentUserMinder,
    currentUser,
    signupInfo,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const showManageListingsLink =
    isCurrentUserMinder || !signupInfo || signupInfo.signupOrigin === 'oogoEvent';

  let tabs = [
    {
      text: (
        <FormattedMessage
          id={isCurrentUserMinder ? 'UserNav.yourListings' : 'UserNav.yourListings_job'}
        />
      ),
      selected: currentPage === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];
  if (!showManageListingsLink) {
    tabs = tabs.filter(tab => tab.linkProps.name !== 'ManageListingsPage');
  }

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
  /* Custom */
  isCurrentUserMinder: false,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
  /* Custom */
  isCurrentUserMinder: bool,
};
const mapStateToProps = state => {
  const {
    /* custom */
    isCurrentUserMinder,
    currentUser,
    signupInfo,
  } = state.user;
  return {
    /* custom */
    isCurrentUserMinder,
    currentUser,
    signupInfo,
  };
};

export default connect(mapStateToProps)(UserNav);
