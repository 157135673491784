import { sanitizeUrl } from '../util/sanitize';
import { validate as uuidValidate } from 'uuid';
import {
  BABY_HAMPER_TYPEFORM_ID,
  BACK_TO_WORK_HAMPER_TYPEFORM_ID,
  CRECHE_HAMPER_TYPEFORM_ID,
} from './configCustomTypeform';

// Define common attributes
const defaultCreditParams = {
  available: 0,
  used: 0,
  used_date: [],
};

// template of credits to be added to corporate users
export const CREDITS_TEMPLATE = typeformSourceId => {
  return [
    {
      key: 'baby_massage',
      params: {
        ...defaultCreditParams,
        filterId: 'jobFilters_pregnancy_and_birth',
        label: 'Baby Massage',
        type: 'preferred',
        preferred_ids: [],
      },
    },
    {
      key: 'back_to_work_coach',
      params: {
        ...defaultCreditParams,
        filterId: 'back_work_coach',
        label: 'Back-to-Work Coaching',
        type: 'category',
      },
    },
    {
      key: 'sleep_expert',
      params: {
        ...defaultCreditParams,
        filterId: 'sleep_consultant',
        label: 'Sleep Consultation',
        type: 'category',
      },
    },
    {
      key: 'baby_hamper',
      params: {
        ...defaultCreditParams,
        label: 'Baby Hamper',
        type: 'typeform',
        typeformId: BABY_HAMPER_TYPEFORM_ID,
      },
    },
    {
      key: 'creche_hamper',
      params: {
        ...defaultCreditParams,
        label: 'Starting Crèche Hamper',
        type: 'typeform',
        typeformId: CRECHE_HAMPER_TYPEFORM_ID,
      },
    },
    {
      key: 'back_to_work_hamper',
      params: {
        ...defaultCreditParams,
        label: 'Back-to-Work Hamper',
        type: 'typeform',
        typeformId: BACK_TO_WORK_HAMPER_TYPEFORM_ID,
      },
    },
    {
      key: 'source_childcare',
      params: {
        ...defaultCreditParams,
        label: 'Childcare Search',
        type: 'typeform',
        typeformId: typeformSourceId,
      },
    },
    {
      key: 'day_to_day_childcare',
      params: {
        ...defaultCreditParams,
        inProgress: 0,
        label: 'Complementary Babysit',
        type: 'cluster',
      },
    },
    {
      key: 'meal_voucher',
      params: {
        ...defaultCreditParams,
        label: 'Meal Voucher',
        type: 'voucher',
        vouchers: [
          {
            id: 1,
            isNew: false,
            voucher_url: '',
          },
        ],
      },
    },
  ];
};

export const CREDITS_ORDER_TEMPLATE = [
  'baby_massage',
  'baby_hamper',
  'sleep_expert',
  'back_to_work_coach',
  'back_to_work_hamper',
  'creche_hamper',
  'day_to_day_childcare',
  'source_childcare',
];

// Sort credits based on creditsOrder
export const sortCredits = (credits, creditsOrder) => {
  return credits.sort((a, b) => {
    const indexA = creditsOrder.indexOf(a.key);
    const indexB = creditsOrder.indexOf(b.key);
    if (indexA === -1) {
      return 1; // Assign a higher index value for missing elements
    }
    if (indexB === -1) {
      return -1; // Assign a higher index value for missing elements
    }
    return indexA - indexB;
  });
};

// Reduce sortedCredits to get availableCredits, usedCredits, and inProgressCredits
export const getCredits = (sortedCredits, cluster) => {
  return sortedCredits.reduce(
    (acc, credit) => {
      if (credit.params?.available > 0) {
        if (credit.params.type === 'cluster') {
          const key = `${credit.key}`;
          if (key in cluster) {
            credit.params.cluster = cluster[key];
          }
        } else if (credit.params.type === 'preferred') {
          const preferredIds = credit.params.preferred_ids || [];
          const validIds = preferredIds.filter(id => uuidValidate(id));
          if (validIds.length > 0) {
            credit.params['preferred_ids'] = validIds;
          }
        } else if (credit.params.type === 'voucher') {
          const vouchersRaw = credit.params.vouchers || [];
          let isNewCounter = 0;
          let isUsedCounter = 0;
          const vouchers = Array.isArray(vouchersRaw)
            ? vouchersRaw.reduce((result, item, index) => {
                const sanitizedUrl = sanitizeUrl(item.voucher_url);

                if (sanitizedUrl !== 'about:blank') {
                  if (item.isNew) {
                    isNewCounter++;
                    result.push({
                      linkURL: sanitizedUrl,
                      isNew: item.isNew,
                      id: item.id,
                      index: index,
                    });
                  } else {
                    isUsedCounter++;
                  }
                }
                return result;
              }, [])
            : [];
          if (vouchers.length > 0) {
            credit.params['newVouchers'] = vouchers;
          }
          credit.params['available'] = isNewCounter;
          credit.params['used'] = isUsedCounter;
        }
        if (credit.params?.available > 0) {
          acc.availableCredits.push(credit);
        }
      }
      if (credit.params?.used > 0) {
        if (credit.params.type === 'voucher') {
          const vouchersRaw = credit.params.vouchers || [];
          let isUsedCounter = 0;
          const vouchers = Array.isArray(vouchersRaw)
            ? vouchersRaw.reduce((result, item, index) => {
                if (!item.isNew) {
                  const sanitizedUrl = sanitizeUrl(item.voucher_url);
                  if (sanitizedUrl !== 'about:blank') {
                    isUsedCounter++;
                    result.push({
                      linkURL: sanitizedUrl,
                      isNew: item.isNew,
                      id: item.id,
                      index: index,
                    });
                  }
                }
                return result;
              }, [])
            : [];
          credit.params['usedVouchers'] = vouchers;
          credit.params['used'] = isUsedCounter;
        }

        acc.usedCredits.push(credit);
      }
      if (credit.params?.inProgress > 0) {
        acc.inProgressCredits.push(credit);
      }
      return acc;
    },
    { availableCredits: [], usedCredits: [], inProgressCredits: [] }
  );
};
