import { validate as uuidValidate } from 'uuid';

export const SOURCE_RESULTS = [
  {
    groupId: 'day_to_day_childcare',
    label: 'Day-To-Day-Childcare',
    list: [{ listingId: '', notes: ['', '', ''] }],
  },
  { groupId: 'backup', label: 'Back Up Minder™', list: [{ listingId: '', notes: ['', '', ''] }] },
  {
    groupId: 'tutoring_activity',
    label: 'Tutoring & Activities',
    list: [{ listingId: '', notes: ['', '', ''] }],
  },
  { groupId: 'creche', label: 'Creche', list: [{ listingId: '', notes: ['', '', ''] }] },
  {
    groupId: 'pregnancy_and_birth',
    label: 'Pregnancy & Birth',
    list: [{ listingId: '', notes: ['', '', ''] }],
  },
];

export const transformSourceResults = sourceResults => {
  return sourceResults.reduce(
    (acc, group) => ({
      ...acc,
      [group.groupId]: group.list
        .filter(
          item =>
            (!item.status || item.status === 'like') &&
            item.listingId &&
            uuidValidate(item.listingId)
        )
        .map(item => item.listingId),
    }),
    {}
  );
};
