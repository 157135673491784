import React, { useEffect } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import loadable from '@loadable/component';

import { propTypes } from '../../util/types';
import {
  sendVerificationEmail,
  hasCurrentUserErrors,
  setUserListInteractive,
} from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/auth.duck';
import { manageDisableScrolling } from '../../ducks/ui.duck';

const Topbar = loadable(() => import(/* webpackChunkName: "Topbar" */ './Topbar/Topbar'));

export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    history,
    isAuthenticated,
    isLoggedInAs,
    authScopes,
    hasGenericError,
    location,
    notificationCount,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    /* custom */
    currentUserCorporate,
    isCurrentUserInteractive,
    onSetUserListInteractive,
    userListInteractive,
    isCurrentUserCorporate,
    isCurrentUserMinder,
    signupInfo,
    ...rest
  } = props;
  useEffect(() => {
    if (currentUser) {
      setTidioUserAttributes(
        currentUser.id.uuid,
        currentUser.attributes.profile.displayName,
        currentUser.attributes.email,
        currentUser.attributes.profile.protectedData.phoneNumber || '',
        currentUser.attributes.profile.publicData.userType
      );
    }
  }, [currentUser]);

  const setTidioUserAttributes = (userId, userName, userEmail, userPhone, userRole) => {
    const identifyVisitor = () => {
      document.tidioIdentify = {
        distinct_id: userId,
        email: userEmail,
        name: userName,
        phone: userPhone,
        tags: [userRole],
      };
    };
    const setVisitorData = () => {
      window.tidioChatApi.setVisitorData({
        distinct_id: userId,
        email: userEmail,
        name: userName,
        phone: userPhone,
        tags: [userRole],
      });
    };
    if (window.tidioChatApi) {
      identifyVisitor();
      setVisitorData();
    } else {
      const handler = () => {
        identifyVisitor();
        setVisitorData();
        document.removeEventListener('tidioChat-ready', handler);
      };
      document.addEventListener('tidioChat-ready', handler);
    }
  };

  return (
    <Topbar
      authInProgress={authInProgress}
      currentPage={currentPage}
      currentSearchParams={currentSearchParams}
      currentUser={currentUser}
      currentUserHasListings={currentUserHasListings}
      currentUserHasOrders={currentUserHasOrders}
      history={history}
      isAuthenticated={isAuthenticated}
      isLoggedInAs={isLoggedInAs}
      authScopes={authScopes}
      location={location}
      notificationCount={notificationCount}
      onLogout={onLogout}
      onManageDisableScrolling={onManageDisableScrolling}
      onResendVerificationEmail={onResendVerificationEmail}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      sendVerificationEmailError={sendVerificationEmailError}
      showGenericError={hasGenericError}
      /* custom */
      currentUserCorporate={currentUserCorporate}
      isCurrentUserInteractive={isCurrentUserInteractive}
      onSetUserListInteractive={onSetUserListInteractive}
      userListInteractive={userListInteractive}
      isCurrentUserCorporate={isCurrentUserCorporate}
      isCurrentUserMinder={isCurrentUserMinder}
      signupInfo={signupInfo}
      {...rest}
    />
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  authScopes: null,
  /* custom */
  currentUserCorporate: null,
  isCurrentUserInteractive: false,
  userListInteractive: null,
  isCurrentUserCorporate: false,
  isCurrentUserMinder: false,
  signupInfo: null,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  isLoggedInAs: bool.isRequired,
  authScopes: array,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,
  /* custom */
  currentUserCorporate: string,
  isCurrentUserInteractive: bool,
  userListInteractive: array,
  isCurrentUserCorporate: bool,
  isCurrentUserMinder: bool,
  signupInfo: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated and isLoggedInAs
  const { isAuthenticated, isLoggedInAs, logoutError, authScopes } = state.auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    /* custom */
    currentUserCorporate,
    isCurrentUserInteractive,
    userListInteractive,
    isCurrentUserCorporate,
    isCurrentUserMinder,
    signupInfo,
  } = state.user;
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    notificationCount,
    isAuthenticated,
    isLoggedInAs,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
    /* custom */
    currentUserCorporate,
    isCurrentUserInteractive,
    userListInteractive,
    isCurrentUserCorporate,
    isCurrentUserMinder,
    signupInfo,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onSetUserListInteractive: params => dispatch(setUserListInteractive(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TopbarContainerComponent);

export default TopbarContainer;
